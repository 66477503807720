<template>
  <div id="app">
    <common-header v-if="!hideHeader" ref="changePasswordView" />
    <router-view />
    <common-footer v-if="!hideFooter"></common-footer>
  </div>
</template>
<script>
import CommonHeader from "@/views/common/Header.vue";
import CommonFooter from "@/views/common/Footer.vue";
export default {
  name: "AppView",
  components: {
    CommonHeader,
    CommonFooter,
  },
  data() {
    return {
      hideFooter: false,
      hideHeader: false,
    };
  },
  watch: {
    $route: function (value) {
      if (value.name === "ChangePassword" || value.name === "Personal") {
        this.$nextTick(() => {
          this.$refs.changePasswordView.setHiden(true);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.changePasswordView.setHiden(false);
        });
      }

      if (
        value.name === "data-resources" ||
        value.name === "model-library" ||
        value.name === "ProjectEdit" ||
        value.name === "Workbench"
      ) {
        this.hideFooter = true;
      } else if (value.name === "ChangePassword") {
        this.hideHeader = true;
        this.hideFooter = true;
      } else {
        this.hideHeader = false;
        this.hideFooter = false;
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Inter;
  //font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
@font-face {
  font-family: Plus Jakarta Sans;
  src: url("@/assets/fonts/PlusJakartaSans-Regular.ttf");
}
@font-face {
  font-family: Plus Jakarta Sans Bd;
  src: url("@/assets/fonts/PlusJakartaSans-Bold.ttf");
}
</style>
