import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import ElementUI from "element-ui";
import VueLazyload from "vue-lazyload";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { mainAppInit } from "@zjlab-fe/util";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@/styles/common.scss";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueLazyload);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.config.ignoredElements = ["spline-viewer"];

mainAppInit();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
