import Vue from "vue";
import VueRouter from "vue-router";
import { ajax } from "@/views/common/method-util.js";
import ProjectEdit from "@/views/workbench/ProjectEdit.vue";

Vue.use(VueRouter);

const routesAll = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/HomeView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/privacy-policy/PrivacyPolicyView.vue"
      ),
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/terms-conditions/TermsConditionsView.vue"
      ),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/HomeView.vue"),
  },
  {
    path: "/geo-gpt",
    name: "geo-gpt",
    component: () =>
      import(/* webpackChunkName: "gpt" */ "../views/geo-gpt/index.vue"),
  },
  {
    path: "/astro-one",
    name: "astro-one",
    component: () =>
      import(/* webpackChunkName: "one" */ "../views/astro-one/index.vue"),
  },
  {
    path: "/model-library",
    name: "model-library",
    component: () =>
      import(/* webpackChunkName: "model" */ "../views/resource/model.vue"),
  },
  {
    path: "/data-resources",
    name: "data-resources",
    component: () =>
      import(/* webpackChunkName: "data" */ "../views/resource/data.vue"),
  },
  // {
  //   path: "/projects-programs",
  //   name: "projects-programs",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/projects-programs/ProjectsProgramsView.vue"
  //     ),
  // },
  // {
  //   path: "/resource-hub",
  //   name: "resource-hub",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/resource-hub/ResourceHubView.vue"
  //     ),
  // },
  {
    path: "/news-events",
    name: "news-events",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/news-events/NewsEventsView.vue"
      ),
  },
  // {
  //   path: "/news-events/:id",
  //   name: "news-events-detail",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/news-events/NewsEventsDetail.vue"
  //     ),
  // },
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/personal/index.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../views/personal/ChangePassword.vue"),
  },
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("@/views/echarts/MapBg.vue"),
  // },
  {
    path: "/workbench",
    name: "Workbench",
    component: () => import("@/views/workbench/index.vue"),
    children: [
      {
        path: "project-edit",
        name: "ProjectEdit",
        component: ProjectEdit,
      },
    ],
  },
];
const routesDomestic = [
  "home",
  "about-us",
  "geo-gpt",
  "astro-one",
  "news-events",
  "Workbench",
  "Personal",
  "terms-conditions",
  "privacy-policy",
  "ChangePassword",
];
const routesInternational = [
  "home",
  "about-us",
  "geo-gpt",
  "news-events",
  "Personal",
  "terms-conditions",
  "privacy-policy",
  "ChangePassword",
];
// 根据环境变量剔除特定路由
const routes =
  process.env.VUE_APP_DEFAULT_VERSION == "domestic"
    ? routesAll.filter((route) => routesDomestic.includes(route.name))
    : process.env.VUE_APP_DEFAULT_VERSION == "international"
    ? routesAll.filter((route) => routesInternational.includes(route.name))
    : routesAll;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/personal" ||
    to.path === "/workbench" ||
    to.path === "/workbench/project-edit"
  ) {
    setTimeout(() => {
      if (!localStorage.getItem("isLogin")) {
        ajax("/sso/getSsoAuthUrl", { clientLoginUrl: location.href }, (res) => {
          location.href = res.data;
        });
      } else {
        next();
      }
    }, 500);
  }
  if (to.path === "/data-resources" || to.path === "/model-library") {
    document.body.classList.add("hide-scrollbar");
  } else {
    document.body.classList.remove("hide-scrollbar");
  }
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
