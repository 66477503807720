var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(!_vm.isLogin)?_c('button',{staticClass:"sign-in-btn",on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.$t("common.signIn"))+" ")]):_c('span',{staticClass:"login",class:['Personal', 'ChangePassword'].includes(_vm.$route.name) ? 'active' : '',on:{"click":_vm.checkUser}},[_c('div',{style:({
        width: '36px',
        height: '36px',
        borderRadius: '18px',
        textalign: 'center',
        color: '#fff',
        lineHeight: '36px',
        margin: '0 auto',
        background: _vm.getUserColor(_vm.user?.username),
      })},[_vm._v(" "+_vm._s(_vm.user?.username?.slice(0, 2) || "-")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }