import { render, staticRenderFns } from "./DropdownMenu.vue?vue&type=template&id=774eb999&scoped=true"
import script from "./DropdownMenu.vue?vue&type=script&lang=js"
export * from "./DropdownMenu.vue?vue&type=script&lang=js"
import style0 from "./DropdownMenu.vue?vue&type=style&index=0&id=774eb999&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../143/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774eb999",
  null
  
)

export default component.exports