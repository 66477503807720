<template>
  <div class="project-edit">
    <div class="top-bar">
      <div class="banner">
        <span class="worklist" @click="backWorkbench">
          {{ $t("workbench.workbench") }} </span
        >/
        {{ projectName }}
        <span class="edit"> / {{ $t("workbench.editProject") }}</span>
      </div>
      <div class="theme">{{ $t("workbench.editInfo") }}</div>
    </div>
    <div class="edit-content">
      <div class="left-content">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="fieldset-1"
              :label="$t('workbench.projectId')"
              label-for="input-1"
              label-align="left"
            >
              <b-form-input
                id="input-1"
                v-model="projectCode"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="fieldset-2"
              :label="$t('workbench.projectItem')"
              label-for="input-2"
              label-align="left"
            >
              <b-form-input
                id="input-2"
                v-model="projectName"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label-align="left">
              <template #label>
                <icon-required />
                {{ $t("workbench.projectDes") }}
              </template>
              <b-form-textarea
                v-model="projectDesc"
                @change="handleCheck"
                placeholder=""
                rows="5"
                no-resize
                class="desc-input"
              ></b-form-textarea>
            </b-form-group>
            <div class="prompt" v-show="descAllow">
              {{ $t("workbench.maxiMum") }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              id="fieldset-3"
              :label="$t('workbench.scientificFied')"
              label-for="input-3"
              label-align="left"
            >
              <b-form-input
                id="input-3"
                disabled
                v-model="projectName"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label-align="left">
              <template #label>
                <icon-required />
                {{ $t("workbench.dueDate") }}
              </template>
              <b-form-datepicker
                v-model="dueDate"
                :min="minDate"
                class="mb-2"
                :placeholder="'Please select date'"
                :locale="$store.state.app.lang === 'en' ? 'en' : 'zh-CN'"
                :date-format-options="{
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  weekday: 'long',
                }"
                :no-highlight="true"
              >
                <template #button-content>
                  <date-picker />
                </template>
              </b-form-datepicker>
              <span class="date-icon"><date-picker /></span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              :label="$t('workbench.projectMembers')"
              label-for="my-table"
              label-align="left"
            >
              <div class="table-container">
                <b-table
                  id="my-table"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                >
                  <template #cell(invitedEmail)="data">
                    {{ data.item.invitedEmail || "--" }}
                  </template>
                  <template #cell(username)="data">
                    <div class="member-name">
                      <div v-if="data.item.username">
                        <Avatar
                          :name="data.item.username"
                          :color="getUserColor(data.item.username)"
                        />
                      </div>
                      {{ data.item.username || "&nbsp;&nbsp;--" }}
                    </div>
                  </template>
                  <template #cell(invitationStatus)="data">
                    <div class="table-status">
                      <img
                        v-if="data.item.invitationStatus === 'accepted'"
                        src="@/assets/workbench/accept-icon.png"
                        alt=""
                      />
                      <img
                        v-else-if="
                          data.item.invitationStatus === 'invited' ||
                          data.item.invitationStatus === 'reinvited'
                        "
                        src="@/assets/workbench/pending.png"
                        alt=""
                      />
                      <img v-else src="@/assets/workbench/reject.png" alt="" />
                      {{ showStatusLabel(data.item.invitationStatus) }}
                    </div>
                  </template>
                  <template #cell(actions)="data">
                    <div
                      v-if="data.item.invitationStatus === 'rejected'"
                      class="table-status operation"
                      @click="sendEmailAgain(data.item.invitedEmail)"
                    >
                      <img src="@/assets/workbench/send-email.png" alt="" />
                      <span>{{ $t("workbench.sendInvitaion") }}</span>
                    </div>
                  </template>
                </b-table>
                <!-- <div v-if="items.length" class="overview">
                  Show {{ items.length }} from {{ rows }} Tasks
                </div> -->
                <b-pagination
                  v-if="items.length"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  @change="onPageChange"
                  align="right"
                  aria-controls="my-table"
                ></b-pagination>
                <div v-if="!items.length" class="empty-info">
                  <img src="@/assets/workbench/member-none.png" alt="" />
                  <div>{{ $t("workbench.noMembers") }}</div>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              :label="$t('workbench.inviteMember')"
              label-for="tags-basic"
              label-align="left"
            >
              <b-form-tags
                input-id="tags-basic"
                :placeholder="$t('workbench.addByEnter')"
                @tag-state="onTagState"
                :tag-validator="validateTag"
                @keyup.enter="emailInput"
                :class="{ 'tags-group': textValid }"
                v-model="members"
              ></b-form-tags>
              <div
                class="send-btn"
                :class="{ active: tagsState && members.length }"
                @click="tagsState && members.length && sendEmail()"
              >
                <img
                  v-show="tagsState && members.length"
                  src="@/assets/workbench/can-send-email.png"
                  alt=""
                />
                <img
                  v-show="!tagsState || !members.length"
                  src="@/assets/workbench/no-send-email.png"
                  alt=""
                />
                {{ $t("workbench.send") }}
              </div>
              <div v-if="validationMessage" class="email-prompt">
                {{ validationMessage }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="right-content">
        <div class="title">
          {{ $t("workbench.projectImg") }}
        </div>
        <img
          :src="
            projectImage
              ? require(`@/assets/workbench/${projectImage}.png`)
              : require('@/assets/workbench/avatar1.png')
          "
          alt=""
        />
      </div>
    </div>
    <div class="bottom-bar">
      <div
        class="edit-btn"
        :class="{ disabled: !projectDesc || !dueDate || descAllow }"
        @click="editProject"
      >
        <img src="@/assets/workbench/comfirm.png" alt="" />
        {{ $t("workbench.confirmEdit") }}
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "./components/avatar.vue";
import DatePicker from "@/assets/icon/DatePicker.vue";
import IconRequired from "@/assets/icon/IconRequired.vue";
import i18n from "@/i18n";

import {
  request,
  ajaxGet,
  ajax,
  getAvatarColor,
} from "@/views/common/method-util";
export default {
  name: "project-edit",
  components: {
    Avatar,
    DatePicker,
    IconRequired,
  },
  data() {
    return {
      editItem: {},
      projectId: "",
      projectCode: "",
      projectName: "",
      projectDesc: "",
      descAllow: false,
      perPage: 10,
      currentPage: 1,
      dueDate: "",
      items: [],
      isLoading: false,
      rows: 0,
      projectImage: "",
      membersEmail: [],
      minDate: this.getTomorrowDate(), // 最小日期（明天）
      validationMessage: "", // 校验提示语
      fields: [
        { key: "invitedEmail", label: i18n.t("workbench.email") },
        {
          key: "username",
          label: i18n.t("workbench.username"),
          headerStyle: { textAlign: "left" },
          thClass: "text-left-header",
        },
        { key: "invitationStatus", label: i18n.t("workbench.joinStatus") },
        { key: "actions", label: i18n.t("workbench.operation") },
      ],
      // 给定的四个背景色
      members: [],
      tagsState: true, // 校验状态
      textValid: false,
    };
  },
  async created() {
    this.projectId = this.$route.query.id;
    this.getProjectInfo();
    this.getAllMemberList();
    await this.getMemberList(this.currentPage);
  },
  methods: {
    getProjectInfo() {
      ajaxGet(
        `/project/findProjectDetailById/${this.projectId}`,
        {},
        (res) => {
          if (res.code === 200 && res.data) {
            this.projectName = res.data.projectName;
            this.projectCode = res.data.projectCode;
            this.dueDate = res.data.dueDate;
            this.projectImage = res.data.projectImage;
            this.projectDesc = res.data.projectDescrption;
          } else {
            console.log("获取信息失败");
          }
        },
        true
      );
    },
    getMemberList() {
      this.isLoading = true;
      ajaxGet(
        `/project/project-member/getMembersByProjectId/${this.projectId}`,
        { pageSize: 10, pageIndex: this.currentPage },
        (res) => {
          if (res.code === 200 && res.data) {
            this.isLoading = false;
            this.items = res.data;
            this.rows = res.page.totalCount;
          } else {
            this.isLoading = false;
            console.log("获取信息失败");
          }
        },
        true
      );
    },
    getAllMemberList() {
      this.isLoading = true;
      ajaxGet(
        `/project/project-member/getMembersByProjectId/${this.projectId}`,
        { pageSize: 1000, pageIndex: 1 },
        (res) => {
          if (res.code === 200 && res.data) {
            this.membersEmail = res.data.map((item) => item.invitedEmail);
          } else {
            this.membersEmail = [];
            console.log("获取信息失败");
          }
        },
        true
      );
    },
    backWorkbench() {
      this.$router.push({ name: "Workbench" });
    },
    async onPageChange(page) {
      this.currentPage = page;
      await this.getMemberList();
    },
    handleCheck() {
      if (this.projectDesc.length > 500) {
        this.descAllow = true;
      } else {
        this.descAllow = false;
      }
    },
    getTomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    },
    showStatusLabel(status) {
      if (status === "invited" || status === "reinvited") {
        return i18n.t("workbench.pending");
      }
      if (this.$store.state.app.lang === "zh") {
        if (status === "accepted") {
          return i18n.t("workbench.isAccepted");
        }
        if (status === "rejected") {
          return i18n.t("workbench.isRejected");
        }
      } else {
        return status.charAt(0).toUpperCase() + status.slice(1);
      }
    },
    getUserColor(name) {
      return getAvatarColor(name);
    },
    validateTag(tag) {
      // 校验规则：必须是邮箱格式且不能重复
      const isEmail =
        /^([A-Za-z0-9_\-+.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/.test(
          tag
        );
      if (!isEmail) {
        this.validationMessage = i18n.t("workbench.invalidEmail");
      }
      const isUnique = !this.membersEmail.includes(tag);
      if (!isUnique) {
        this.validationMessage = i18n.t("workbench.duplicateMsg");
      }
      this.tagsState = isEmail && isUnique;
      if (this.tagsState) {
        this.validationMessage = "";
      }
      return this.tagsState;
    },
    onTagState(valid, invalid, duplicate) {
      if (!invalid.length && !duplicate.length) {
        this.tagsState = true;
      }
      if (valid.length) {
        this.textValid = false;
      }
      if (!invalid.length) {
        this.validationMessage = "";
      }
      if (duplicate.length) {
        this.validationMessage = i18n.t("workbench.duplicateMsg");
      }
    },
    sendEmail() {
      request(
        `/project/invite/${this.projectId}`,
        this.members,
        (res) => {
          if (res.code === 200) {
            if (res.data.length !== this.members.length) {
              const errorEmail = this.members.filter(
                (item) => !res.data.includes(item)
              );
              let errorMsg = "";
              errorEmail.map((item, index) => {
                return (errorMsg += `${i18n.t("workbench.emailName")}${
                  index + 1
                }：${item}\n`);
              });
              this.$bvToast.toast(
                `${errorMsg}${i18n.t("workbench.inviteFail")}`,
                {
                  title: "Message",
                  variant: "danger",
                  solid: true,
                  autoHideDelay: 5000,
                  bodyClass: "toast-message",
                }
              );
            } else {
              this.$bvToast.toast(res.msg, {
                title: "Message",
                autoHideDelay: 5000,
                variant: "success",
                solid: true,
              });
            }
            setTimeout(() => {
              location.reload(true);
            }, 500);
          } else {
            this.$bvToast.toast(res.msg, {
              title: "Message",
              autoHideDelay: 5000,
              variant: "warn",
              solid: true,
            });
          }
        },
        true
      );
    },
    sendEmailAgain(email) {
      ajax(
        `/project/reinvited/${this.projectId}`,
        { email: email },
        (res) => {
          if (res.code === 200) {
            this.$bvToast.toast(res.msg, {
              title: "Message",
              autoHideDelay: 5000,
              variant: "success",
              solid: true,
            });
            setTimeout(() => {
              location.reload(true);
            }, 300);
          } else {
            this.$bvToast.toast(res.msg, {
              title: "Message",
              autoHideDelay: 5000,
              variant: "warn",
              solid: true,
            });
          }
        },
        true
      );
    },
    editProject() {
      if (!this.dueDate || !this.projectDesc || this.descAllow) {
        return;
      }
      request(
        `/project/edit/${this.projectId}`,
        {
          projectDescrption: this.projectDesc,
          dueDate: this.dueDate,
        },
        (res) => {
          if (res.code === 200) {
            this.$bvToast.toast(res.msg, {
              title: "Message",
              autoHideDelay: 5000,
              variant: "success",
              solid: true,
            });
            setTimeout(() => {
              window.location.href = "/workbench";
            }, 500);
            // this.getProjectInfo();
          } else {
            this.$bvToast.toast(res.msg, {
              title: "Message",
              autoHideDelay: 5000,
              variant: "warn",
              solid: true,
            });
          }
        },
        true
      );
    },
    emailInput() {
      this.textValid = this.validationMessage;
    },
  },
};
</script>
<style lang="scss" scoped>
.project-edit {
  background: #fbfafc;
  min-height: calc(100vh - 72px);
}
.top-bar {
  display: flex;
  width: 100%;
  padding: 20px 80px 16px 150px;
  align-items: flex-start;
  flex-direction: column;
  .banner {
    color: #b6b4ba;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    .worklist {
      cursor: pointer;
      &:hover {
        color: #201f22;
      }
    }
    .edit {
      color: #201f22;
    }
  }
  .theme {
    color: #2e2c34;
    margin-top: 20px;
    font-family: Manrope;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
}
.edit-content {
  margin: 0 150px 0 150px;
  padding: 33px 0 124px 90px;
  border-top: 1px solid #ebeaed;
  display: flex;
  .left-content {
    width: 75%;
    .prompt {
      margin-top: -10px;
      text-align: left;
      color: #fc3400;
      font-size: 13px;
      padding-bottom: 15px;
    }
    .member-name {
      display: flex;
      justify-content: left;
      gap: 12px;
      align-items: center;
    }
  }
  .right-content {
    width: 25%;
    text-align: left;
    margin-left: 40px;
    .title {
      color: #504f54;
      font-family: Manrope;
      font-size: 14px;
      font-weight: 500;
    }
    img {
      margin-top: 8px;
      border-radius: 4px;
      width: 183px;
      height: 183px;
      flex-shrink: 0;
    }
  }
}

.bottom-bar {
  border-top: 1px solid #ebeaed;
  width: 100%;
  height: 74px;
  flex-shrink: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 9;
  .edit-btn {
    display: flex;
    width: 163px;
    height: 42px;
    padding: 11px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #2e4fff;
    color: #fbfafc;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &.disabled {
      opacity: 0.65;
      cursor: default;
    }
  }
}
.desc-input {
  height: 100px;
  text-align: left;
  vertical-align: top;
  white-space: pre-wrap;
  padding: 8px 12px !important;
}
:deep(.b-form-tags) {
  min-height: 86px;
  padding-bottom: 30px;
  padding: 8px 12px !important;
  &.focus {
    box-shadow: none;
  }
  .text-muted {
    display: none;
  }
}
:deep(.align-items-baseline) {
  border-radius: 4px;
  border: 1px solid #ebeaed;
  background: #fbfafc;
  color: #2e2c34;
  font-size: 14px;
  padding: 6px 8px;
}
:deep(.send-btn) {
  position: absolute;
  right: 40px;
  bottom: 22px;
  color: #84818a;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  // cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  &.active {
    color: #007eff;
    cursor: pointer;
  }
}
.email-prompt {
  color: #fc3400;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: -10px;
}
:deep(.form-control) {
  border-radius: 4px;
  color: #2e2c34;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ebeaed;
  padding: 14px 12px;
  &:disabled {
    background-color: #fff;
    cursor: no-drop;
  }
  &:focus {
    border-color: none !important;
    box-shadow: none;
  }
}

:deep(.text-left) {
  color: #504f54;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
:deep(.b-form-btn-label-control) {
  &.form-control {
    & > .form-control {
      text-align: left;
      padding-left: 12px;
    }
    & > .btn {
      position: absolute;
      line-height: 37px;
      visibility: hidden;
    }
    &.focus {
      box-shadow: none;
    }
  }
}
:deep(.b-calendar-header) {
  .form-control {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.table-container {
  border-radius: 4px;
  border: 1px solid #e3e1e5;
  background: #fff;
  padding: 29px 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 340px;
  position: relative;
  :deep(.table) {
    border-bottom: 1px solid #ebeaed;
    th {
      border: none;
      color: #84818a;
      font-family: Manrope;
      font-size: 12px;
      font-weight: 600;
    }
    td {
      border-top: 1px solid #ebeaed;
      color: #2e2c34;
      font-family: Manrope;
      font-size: 14px;
      font-weight: 500;
      vertical-align: middle;
    }
  }
}
:deep(.table-status) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
:deep(.text-left-header) {
  text-align: left;
}
.operation {
  cursor: pointer;
  &:hover {
    color: #007eff;
  }
}
:deep(.invalid-feedback) {
  display: none !important;
}
:deep(.b-form-tags-button) {
  display: none;
}

:deep(.b-form-tag) {
  &.invalid {
    background-color: rgba(252, 52, 0, 0.1); /* 无效标签的背景色 */
    border: 1px solid var(--Fill-Dark-Dark-5, #ebeaed);
  }
}
.overview {
  color: #84818a;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  bottom: 29px;
}
.empty-info {
  padding-bottom: 20px;
  img {
    width: 172px;
    height: 134px;
    flex-shrink: 0;
    margin-bottom: 20px;
  }
  color: #b6b4ba;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.pagination {
  margin: 0;
  :deep(.page-link) {
    border: none;
  }
  :deep(.page-item) {
    width: 25px;
    height: 25px;
    margin-left: 18px;
    &.active {
      .page-link {
        color: #007eff;
        background: rgba(0, 126, 255, 0.1);
        border-radius: 4px;
      }
    }
    .page-link {
      color: #84818a;
      text-align: center;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        box-shadow: none;
      }
    }
    &:first-child,
    &:last-child {
      .page-link {
        font-size: 22px;
        font-weight: 400;
        padding-top: 3px;
      }
    }
  }
}
:deep(.tags-group) {
  .b-form-tags-input {
    color: #fc3400;
  }
}
:deep(.date-icon) {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  right: 25px;
}
:deep(.toast-message) {
  white-space: pre-line; /* 允许 \n 换行 */
}
</style>
