import axios from "axios";
import { getLanguage, LANGUAGE } from "@/i18n";
import Vue from "vue";
import i18n from "@/i18n";
const showToast = function (message, options = {}) {
  const tempVm = new Vue();
  const defaultOptions = {
    title: options.title || i18n.t("common.msg"),
    variant: options.variant || "success",
    solid: true,
    autoHideDelay: 5000,
  };
  tempVm.$bvToast.toast(message, { ...defaultOptions, ...options });
  setTimeout(() => {
    tempVm.$destroy();
  }, 5000);
};

// sso-client 的后端服务地址
export const baseUrl = "/api";

const validStatusCodes = new Set([
  11001, 11002, 11003, 11011, 11012, 11013, 11014, 11015, 11016, 30105, 30106,
  30107,
]);

const isValidStatusCode = function (status) {
  return validStatusCodes.has(status);
};

// 定义Content-Type枚举
export const ContentType = {
  JSON: "application/json;charset=UTF-8",
  FORM_DATA: "multipart/form-data",
};
// 封装一下 Ajax 方法
export const ajax = function (path, data, successFn, ignoreMsg = false) {
  if (
    path !== "/sso/getSsoAuthUrl" &&
    path !== "/sso/doLoginByCode" &&
    !localStorage.getItem("portaltoken")
  ) {
    return;
  }
  axios({
    url: baseUrl + path,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      portaltoken: localStorage.getItem("portaltoken"),
      [LANGUAGE]: getLanguage(),
    },
  })
    .then(function (response) {
      // 成功时执行
      const res = response.data;
      if (res.code === 200) {
        successFn(res);
      } else if (isValidStatusCode(res.code)) {
        showToast(i18n.t("common.loginFail"), { variant: "danger" });
        setTimeout(() => {
          localStorage.removeItem("portaltoken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("isLogin");
          window.location.href = "/";
        }, 1000);
      } else {
        showToast(res.msg, { variant: "danger" });
      }
    })
    .catch(function (error) {
      if (!ignoreMsg) {
        console.log("请求异常", error);

        // return alert("异常：" + JSON.stringify(error));
      }
    });
};
// 封装一下 Ajax 方法
export const request = function (
  path,
  data,
  successFn,
  ignoreMsg = false,
  method
) {
  if (!localStorage.getItem("portaltoken")) {
    return;
  }
  axios({
    url: baseUrl + path,
    method: method ? method : "post",
    data: data,
    headers: {
      "Content-Type": ContentType.JSON,
      portaltoken: localStorage.getItem("portaltoken"),
      [LANGUAGE]: getLanguage(),
    },
  })
    .then(function (response) {
      // 成功时执行
      const res = response.data;
      if (res.code === 200) {
        successFn(res);
      } else if (isValidStatusCode(res.code)) {
        showToast(i18n.t("common.loginFail"), { variant: "danger" });
        setTimeout(() => {
          localStorage.removeItem("portaltoken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("isLogin");
          window.location.href = "/";
        }, 1000);
      } else {
        showToast(res.msg, { variant: "danger" });
      }
    })
    .catch(function (error) {
      if (!ignoreMsg) {
        console.log("请求异常", error);
        // return alert("异常：" + JSON.stringify(error));
      }
    });
};
export const ajaxGet = function (path, data, successFn, ignoreMsg = false) {
  if (!localStorage.getItem("portaltoken")) {
    return;
  }
  axios({
    url: baseUrl + path,
    method: "get", // 1. 改为GET方法
    params: data, // 2. GET参数使用params传递
    headers: {
      portaltoken: localStorage.getItem("portaltoken"),
      [LANGUAGE]: getLanguage(),
    },
  }) // 修正括号闭合语法错误
    .then(function (response) {
      const res = response.data;
      if (res.code === 200) {
        successFn(res); // 5. 修正函数名拼写（假设应为successFn）
      } else if (isValidStatusCode(res.code)) {
        showToast(i18n.t("common.loginFail"), { variant: "danger" });
        setTimeout(() => {
          localStorage.removeItem("portaltoken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("isLogin");
          window.location.href = "/";
        }, 1000);
      } else {
        showToast(res.msg, { variant: "danger" });
      }
    })
    .catch(function (error) {
      if (!ignoreMsg) {
        console.log("请求异常", error);

        // alert("异常: " + JSON.stringify(error));
      }
    });
};

// 从url中查询到指定名称的参数值
export const getParam = function (name, defaultValue) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == name) {
      return decodeURIComponent(pair[1]);
    }
  }
  return defaultValue == undefined ? null : defaultValue;
};

const avatarColors = [
  "#6B4D3D",
  "#294175",
  "#416B69",
  "#D09030",
  "#C1542B",
  "#2D9C6A",
  "#74427D",
  "#CE5069",
  "#E6B220",
  "#2AA8A8",
  "#A83D97",
  "#8CBF3D",
  "#4D2E8C",
  "#FFA726",
  "#3D8C8C",
  "#6B8C3D",
];
// 根据用户名哈希值映射颜色
export const getAvatarColor = function (username) {
  if (!username) return;
  const hash = username
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return avatarColors[hash % avatarColors.length];
};
