<template>
  <b-navbar
    toggleable="lg"
    type="light"
    variant="white"
    class="custom-navbar sticky-top"
  >
    <a href="/" class="logo-link">
      <logo-text></logo-text>
    </a>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="mx-auto">
        <b-nav-item v-if="!isHiden">
          <router-link to="/" exact>{{ $t("common.aboutUs") }}</router-link>
        </b-nav-item>
        <!-- <b-nav-item v-if="isHiden">
          <router-link to="/about-us">{{ $t("common.aboutUs") }}</router-link>
        </b-nav-item>
        <b-nav-item v-if="isHiden">
          <router-link to="/projects-programs">
            {{ $t("common.projectsPrograms") }}
          </router-link>
        </b-nav-item> -->
        <b-nav-item v-if="!isHiden">
          <router-link to="/geo-gpt">{{ $t("common.gpt") }}</router-link>
        </b-nav-item>
        <b-nav-item v-if="!isHiden && showDom">
          <router-link to="/astro-one">
            {{ $t("common.astroOne") }}
          </router-link>
        </b-nav-item>
        <b-nav-item
          v-if="!isHiden && showDom && showDomestic"
          class="dropdown-nav"
        >
          <router-link :to="resourcePath">
            {{ $t("common.resource") }}
          </router-link>
          <dropdown-menu
            class="menu-list"
            @option-changed="navigateTo"
          ></dropdown-menu>
        </b-nav-item>
        <!-- <b-nav-item v-if="!isHiden">
          <a href="javascript:void(0);" class="disabled"> Resource Hub </a>
        </b-nav-item> -->
        <b-nav-item v-if="!isHiden">
          <router-link to="/news-events">{{
            $t("common.newsEvents")
          }}</router-link>
        </b-nav-item>
        <b-nav-item v-if="isLogin && !isHiden && showDom" class="workbench">
          <router-link to="/workbench">
            <div class="workbench-btn">
              <img class="work-icon" src="./workBench.png" alt="" />
              {{ $t("common.workbench") }}
            </div>
          </router-link>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <div
          v-if="curLang == 'en' && showDom"
          @click="handleSwitch"
          class="check-icon"
        >
          <img class="default" src="./icon/switch.svg" alt="" />
          <img class="hover-icon" src="./icon/switchAcitve.svg" alt="" />
        </div>
        <div
          v-if="curLang == 'zh' && showDom"
          @click="handleSwitch"
          class="check-icon"
        >
          <img class="default" src="./icon/switch-zh.svg" alt="" />
          <img class="hover-icon" src="./icon/switchAcitve-zh.svg" alt="" />
        </div>
        <login-bar @check-login="checkLogin"></login-bar>
        <!--        <button class="sign-in-btn" style="display: none">Sign in</button>-->
        <!--        &lt;!&ndash;        <img class="lang-img user-item" src="./icon/lang.svg" alt="" />&ndash;&gt;-->
        <!--        <b-icon class="lang-img user-item" icon="person-fill"></b-icon>-->
        <!--        <b-nav-item-dropdown text="English" right class="user-item">-->
        <!--          <b-dropdown-item href="#">退出</b-dropdown-item>-->
        <!--        </b-nav-item-dropdown>-->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import LogoText from "@/views/common/LogoText.vue";
import LoginBar from "@/views/common/LoginBar.vue";
import DropdownMenu from "./DropdownMenu.vue";
import { getLanguage } from "@/i18n";

export default {
  name: "CommonHeader",
  components: { LogoText, LoginBar, DropdownMenu },
  watch: {
    $route: function (value) {
      this.isHiden = value.path === "/personal";
    },
  },
  data() {
    return {
      isHiden: false,
      curLang: getLanguage(),
      resourcePath: "/model-library",
      isLogin: false,
      showDom: false,
      showDomestic: false,
    };
  },
  created() {
    if (process.env.VUE_APP_DEFAULT_VERSION == "international") {
      this.showDom = false;
      this.showDomestic = true;
    } else if (process.env.VUE_APP_DEFAULT_VERSION == "domestic") {
      this.showDomestic = false;
      this.showDom = true;
    } else {
      this.showDomestic = true;
      this.showDom = true;
    }
  },

  mounted() {
    if (localStorage.getItem("resourceType")) {
      this.resourcePath =
        localStorage.getItem("resourceType") === "model"
          ? "/model-library"
          : "/data-resources";
    }
  },
  methods: {
    setHiden(hidden) {
      this.isHiden = hidden;
    },
    navigateTo(path) {
      this.resourcePath = path;
      this.$router.push({ path });
    },
    handleSwitch() {
      this.curLang = this.curLang === "zh" ? "en" : "zh";
      this.$i18n.locale = this.curLang;

      this.$store.dispatch("app/setLanguage", this.curLang);
      // setLanguage(this.curLang);
    },
    checkLogin(login) {
      this.isLogin = login;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 添加以下样式 */
.navbar-expand-lg .navbar-nav.mx-auto {
  flex: 1;
  justify-content: center;
}
.logo-link {
  line-height: 0;
  text-decoration: none;
}

/* 添加以下样式 */
.custom-navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  height: 72px;
  padding: 0 37px;
}
/* ... 其他样式 ... */
.navbar-nav .nav-item a {
  color: inherit;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
  padding: 0 30px;
}

.navbar-nav .nav-item > a {
  a {
    position: relative;
    display: inline-block;
    padding: 0 5px 4px 5px;
    border-bottom: 4px solid transparent;
    font-size: 16px;
    color: 201F22;
    font-weight: 500;
    &.router-link-active {
      border-color: #2e4fff;
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 0;
      height: 4px; /* 边框的高度 */
      background-color: #2e4fff; /* 边框的颜色 */
      transition: width 0.3s; /* 动画时间 */
    }

    &:hover::after {
      width: 100%; /* 悬停时填充宽度 */
    }

    &.disabled {
      color: #777f8f;
      &::after {
        left: 8px;
        right: 8px;
        background: #555557;
      }
      &:hover::after {
        width: 100px; /* 悬停时填充宽度 */
      }
    }
  }
}
.navbar-collapse {
  .nav-item {
    .nav-link {
      > a {
        line-height: 72px;
        height: 72px;
      }
    }
  }
}
.navbar-nav {
  align-items: center;
  position: relative;
  .nav-item {
    .workbench-btn {
      padding: 0 12px;
      border-radius: 4px;
      border: 1px solid #ebeaed;
      height: 42px;
      line-height: 42px;
      margin-top: 10px;
      .work-icon {
        width: 16px;
        height: 16px;
        aspect-ratio: 1/1;
      }
    }
  }
  .workbench {
    position: absolute;
    right: 0;
    & > a {
      a {
        color: #007eff;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
    }
  }
}
.custom-navbar {
  :deep(.nav-link) {
    font-size: 16px;
    color: #525252 !important;
    &:focus-visible {
      outline: none;
    }
  }
  :deep(.dropdown-menu) {
    min-width: 150px;
    height: auto;
    padding: 10px 0;
    border: none;
    box-shadow: 0 0 6px rgba(32, 31, 34, 0.2);
    .dropdown-item {
      display: inline-block;
      width: 100%;
      padding: 0 18px;
      line-height: 40px;
      font-size: 14px;
      color: #52575c;
      box-sizing: border-box;
      &:hover {
        background: #f6faff;
        color: #007eff;
      }
    }
  }
  :deep(.dropdown-menu-right) {
    left: 50%;
    margin-left: -90px;
  }
}
.lang-img {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.dropdown-nav {
  position: relative;
  .menu-list {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
    transition: transform 0.5s ease, opacity 0.5s ease;
    display: none;
  }
  &:hover {
    .menu-list {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      display: block;
    }
  }
}
.check-icon {
  margin-right: 21px;
  cursor: pointer;
  .hover-icon,
  .default {
    width: 28px;
    height: 28px;
  }
  &:hover {
    .hover-icon {
      display: block;
    }
    .default {
      display: none;
    }
  }
  .hover-icon {
    display: none;
  }
  .default {
    display: block;
  }
}

.default:hover + .hover-icon {
  display: block;
}
</style>
