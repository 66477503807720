<template>
  <div
    class="avatar"
    :style="{
      backgroundColor: color,
      width: '25px',
      height: '25px',
      lineHeight: '25px',
      fontSize: '10px',
    }"
  >
    {{ name?.slice(0, 2) }}
  </div>
</template>

<script>
export default {
  name: "avatar-",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    // 提取姓名的首字母（纯函数）
    initials() {
      if (!this.name) return;
      const names = this.name.split(" ");
      if (names.length >= 2) {
        return `${names[0][0]}${names[1][0]}`.toUpperCase();
      }
      return this.name[0].toUpperCase();
    },
  },
};
</script>

<style scoped>
.avatar {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: white; /* 文字颜色 */
  font-weight: bold;
  margin: 5px; /* 添加间距 */
}
</style>
