export default {
  common: {
    signIn: "Sign in",
    home: "Home",
    aboutUs: "About Us",
    gpt: "GeoGPT",
    projectsPrograms: "Projects and Programs",
    newsEvents: "News & Events",
    workbench: "Workbench",
    geoGPT: "GeoGPT",
    astroOne: "AstroOne",
    resource: "Resource",
    modelLibrary: "Model library",
    dataResources: "Data resources",
    learnMore: "Learn more",
    policyGuidelines: "Policy Guidelines",
    privacyPolicy: "Privacy Notice",
    termsConditions: "Terms of Service",
    support: "Support",
    links: "Links",
    zhejiangLab: "Zhejiang Lab",
    zhijiangDevelopmentFoundation: "Zhijiang Development Foundation",
    intelligentComputing: "Intelligent Computing",
    copyright: "Copyright © 2025, All Rights Reserved by Zhejiang lab",
    loginFail: "Login failure",
    msg: "Message",
  },
  aboutUs: {
    title: "ABOUT US",
    openSource: "Open Resource Innovation",
    openScience1: "Open Science \n research process",
    openScience2: "Open Science \n data",
    openScience3: "Open Science \n infrastructures",
    openScience4: "Comprehensive \n open-source licensing",
    theFuture: "The Future Paradigm Accelerating Research!",
    data: "Data",
    model: "Model",
    computing: "Computing",
    scale: "Scale³",
    currently: `Currently, scientific discovery has entered a new era of AI for Science (AI4S). The large-scale development of
            data, models, and computing has led to a series of technological innovations. However, to further scale up and
            break through the critical transition from quantitative change to qualitative change, it is essential to
            gather global resources. In this context, zero2x was established. The vision of zero2x is to explore
            cooperative mechanisms for open-source innovation, enabling various stakeholders to collaborate and promote
            the construction of open science infrastructure, thereby accelerating scientific transformation and
            technological innovation.`,
    projects: "Projects and programs",
    resources: "Resources for open science",
    researchers: "Researchers for open science",
    overview: "Overview",
    zero2x: `zero2x provides a one-stop portal for resources such as computing power, data, models, and tools,
              enabling various users to efficiently and conveniently access 'AI for Science' resources and services.
              This will enhance research efficiency, collaboration, and the quality of scientific outcomes in the AI
              era, supporting us in creating best practices for open science.`,
    milestone: "Open Science Milestone",
    milestone2024o1: `A Discovery of Two Slow Pulsars with FAST "Ronin" from the Globular Cluster M15`,
    milestone2024o2:
      "Science and Technology Daily | ZJ Lab's AI-Powered Star Searches",
    milestone2024o3: "GeoGPT: A Large Language Model System for Geoscientists",
    milestone2024o4: "The 4th Innovation Forum on Intelligent Computing",
    discoverMore: "Discover More",
  },
  geoGpt: {
    title: "An Open Large Language Model System For Geoscientists",
    desc: "GeoGPT is an open-source, non-profit global geoscience research project, aiming to promote the open science principles of collaboration, sharing, and co-construction.",
    product: "Start Now",
    website: "GeoGPT Website",
    check: "See  what’s new in GeoGPT！",
    mode: "Capacity",
    capacity:
      "GeoGPT is a specialized large language model (LLM) developed \n to support geoscientific research and applications",
    scientist: "Case",
    idea: "Vision",
    language: "A Large language model system \n for geoscientists.",
    languageDesc:
      "GeoGPT can assist in generating research ideas, conducting literature searches, reading literature, extracting data, constructing knowledge graphs, and \n is utilized for the understanding and creation of geological maps.",
    geologic: "GeoGPT-Geological Map Multimodal Model",
    geologicDesc:
      "Multimodal geological map model capable of recognizing geological maps and providing professional Q&A based on them.",
    scientistDesc:
      "Through in-depth collaboration with domain scientists, we jointly develop scientific\nsolutions for specific fields.",
    creature: "Taxonomy Assistant",
    creatureDesc: "Identify and distinguish spore types and characteristics",
    geologicCreate: "MapGenerator",
    geologicCreateDesc: "Draw professional geological maps",
    ideaSummary: "Building a Global Ecosystem for Earth Science Research",
    idea1: "Open Science",
    idea1Detail: "Identify and distinguish spore types \n and characteristics",
    idea2: "AI Ethics",
    idea2Detail: "Through Rigorous Testing and \n Ethical Fine-Tuning",
    idea3: "Equal Science",
    idea3Detail: "AI Promotes Equality and Fairness in \n Scientific Research",
    contact: "Contact us",
  },
  astroOne: {
    title:
      "Efficient and Reliable Research Assistants and Partners for Astronomers",
    product: "Start now",
    website: "AstroOne website",
    specClip:
      "Analyzing spectral data of varying wavelengths aids researchers in discerning celestial bodies' physical and chemical traits and velocity details. This model will be vital in exploring universe evolution, star formation, and galactic dynamics",
    falco:
      "Analyze the time-dependent luminosity changes of target celestial bodies to track their variations, capture abnormal astronomical events promptly, and detect new celestial objects or phenomena.",
    comming: "Coming soon...",
    vl: "Astronomical images are thoroughly interpreted to precisely identify the features of key celestial bodies, supporting scientists in interdisciplinary data analysis and the interpretation of astronomical phenomena.",
    solar:
      "Aiming at using 12-year solar observation data (7 million images and 13 bands), it supports feature extraction and prediction over any time span for precise solar flare forecasts.",
  },
  home: {
    title1: "Build for Scientific",
    title2: "Data Connection",
    ai: {
      title: "AI-Driven,Accelerating the Research Process",
      desc: "The AI tools and algorithms provided by the platform can significantly accelerate your research process.",
    },
    collaboration: {
      title: "Facilitating Interdisciplinary Collaboration",
      desc: "Our platform offers an easy-to-use community and communication space that allows you to collaborate with experts, scholars, and researchers from various fields.",
    },
    science: {
      title: "Promoting Open Science and Knowledge Sharing",
      desc: "Our platform emphasizes open science and resource sharing, supporting global researchers in exchanging and sharing the latest research findings and resources.",
    },
    exploreTheZero2x: "Explore the zero2x",
    exploreImg1: "Earth Science",
    exploreImg2: "Astronomy/Astronomical Science",
    availableOpenResources: "Available Open Resources",
    ourPlatform: "Our platform emphasizes open science and resource sharing",
    computing: "Computing",
    data: "Data",
    models: "Models",
    tools: "Tools",
    latestNews: "Latest News",
    by: "By ",
    zhejiangLab: "ZHEJIANG LAB",
    date1: "10 October 2024",
    date2: "26 June 2024",
    date3: "29 May 2023",
    latestNew1: "The 4th Innovation Forum on Intelligent Computing",
    latestNew2: "Hatching insights: Large language models in life …",
    latestNew3: "Gather in London | UK Talents Exchange Fair In…",
    ourPartner: "Our Partner",
    ourPartnerDesc:
      "Continue to work hard and move forward together on the road to open science",
  },
  newsEvents: {
    title: "NEWS & EVENTS",
    viewMore: "View More",
    title1:
      'A Discovery of Two Slow Pulsars with FAST "Ronin" from the Globular Cluster M15',
    intro1:
      "Globular clusters are gravitationally bound dense stellar clusters, with an evolutionary age generally exceeding 10 billion years, making them one of the oldest celestial systems in the universe.",
    title2: "Science and Technology Daily | ZJ Lab's AI-Powered Star Searches",
    intro2:
      'Located in Pingtang, Guizhou Province, the Five-hundred-meter Aperture Spherical Radio Telescope (FAST), also known as "China\'s Sky Eye", continuously receives radio signals from the depths of the universe.',
    title3:
      'Shortening R&D Time from 3 Months to 2 Weeks! "AI + Life Sciences" Enables ZJ Lab to Find Viruses Faster',
    intro3:
      '"HPV may cause cancer", "About 98% of cervical cancer cases among women in China are caused by high-risk HPVs", and "There is currently no specific medicine for HPV infection"...',
    title4:
      "The Periodic Jet Precession Is Successfully Captured, and the Latest Research Findings on M87",
    intro4:
      "On September 27, 2023, Nature released the latest research findings of an international research team composed of 45 institutions.",
    title6:
      "First Revealing of Continuously-active Repeating Fast Radio Burst in Science",
    intro6:
      "On May 12, 2023, a major discovery in the field of fast radio bursts, the Magnetic Field Reversal in the Turbulent Environment Around a Repeating Fast Radio Burst, was published in Science, a top international academic journal",
    title7:
      "Deep Mining of China's FAST Observation Data | the Latest Results of Computational Astronomy Were… ",
    intro7:
      "On December 26, FENG Yi, research expert at the Research Center for Intelligent Computing Platforms of Zhejiang Lab, and other researchers published their latest research results in the cover article of the Science Bulletin",
    title8: "Nature Communications Reports Device Physics as Algorithms",
    intro8:
      "Recently, artificial intelligence (AI) technologies, represented by foundation models, have witnessed a rapid advancement, which led to a surge in power demand. According to the Artificial Intelligence Index Report 2023 released by the Stanford Institute for Human-Centered AI, a single training run of the GPT-3 language model consumed 1,287 megawatt-hours of electricity...",
    title9:
      "Satellite Remote Sensing Big Data Promotes Sustainable Development 2024 UN Big Data Hackathon China Competition Successfully Held",
    intro9:
      "On December 12, the final and closing ceremony of the 2024 UN Big Data Hackathon China Competition (China Competition in brief), jointly organized by United Nations Global Center for Big Data and Data Science(UNBDC), and Zhejiang Lab (ZJ Lab), concluded at ZJ Lab. ",
    title10: "AstroOne, a Large Language Model for Astronomy Launched",
    intro10:
      "On October 30, AstroOne, a large language model for astronomy jointly developed by Zhejiang Lab and the National Astronomical Observatories, Chinese Academy of Science (NAOC), made its debut at the 2024 Annual Academic Conference of the Chinese Astronomical Society, attracting widespread attention from the astronomy community.",
  },
  workbench: {
    projectName: "Here's what's happening with you.",
    welcome: "Welcome back",
    desc: "Here's what's happening with you.",
    task: "My Projects",
    help: "Documentation",
    add: "New Project",
    taskName: "Task name",
    taskType: "Task type",
    domainDiscipline: "Domain discipline",
    status: "Status",
    startTime: "Start time",
    detail: "Detail",
    edit: "Edit",
    delete: "Delete",
    invitation: "Accept project invitation",
    welcomeProject: "Welcome to participate in the labelling system project.",
    project: "Project name",
    Initiator: "Initiator",
    accept: "Accept",
    reject: "Reject",
    dueTo: "Due to",
    accepted: "To be accepted",
    workbench: "Workbench",
    editProject: "Edit Project",
    editInfo: "Edit project information",
    projectId: "Project ID",
    projectItem: "Project name",
    projectImg: "Project image",
    projectDes: "Project description",
    scientificFied: "Scientific field",
    dueDate: "Due date",
    projectMembers: "Project members",
    email: "Email address",
    username: "Member name",
    joinStatus: "Join status",
    operation: "Operation",
    isAccepted: "Accepted",
    isRejected: "Rejected",
    pending: "Pending",
    sendInvitaion: "Send invitation",
    inviteMember: "Invite members",
    addByEnter: "Add by enter",
    send: "Send",
    confirmEdit: "Confirm edit",
    maxiMum: "Maximum of 500 characters allowed.",
    duplicateMsg: "Duplicate email addresses or invitees detected.",
    invalidEmail: "The email address format is invalid.",
    noMembers: "Members are still on the way",
    noProject: "Projects are still on the way",
    emailName: "email",
    inviteFail: "Invitation failed",
  },

  privacyPolicy: {
    title: "Privacy Notice",
  },
  projectsPrograms: {
    title: "PROJECTS & PROGRAMS",
    subhead1: "An Open-Source and Non-Profit Exploratory Research Project",
    intro1:
      "GeoGPT, an exploratory geoscience project, aims to develop open-source, non-profit AI for novel perspectives & tools in global geoscience research.",
    subhead2:
      "Empowering Astronomical and Astrophysical Research with Advanced AI Capabilities",
    intro2:
      "AstroOne is an open-source, non-profit exploratory research project for astronomical and astrophysical research, offering novel LLM-augmented capabilities and tools for astronomers. Hundreds of AI and astronomy experts from National Astronomical Observatories, Chinese Academy of Sciences and Zhejiang Lab have participated in the development of AstroOne prototype.",
    viewMore: "View More",
  },

  termsConditions: {
    title: "Terms of Service",
  },
  personal: {
    setting: "Settings",
    update: "Update and manage your account",
    profile: "Personal Profile",
    edit: "Edit your profile",
    name: "Your name",
    rules: "Only supported 24-character letters + numbers + spaces",
    email: "Email address",
    save: "Save Changes",
    account: "Account Settings",
    change: "Change your account password",
    password: "Change password",
    logout: "Log out of zero2x system",
    exit: "Exit",
    updatePassword: "Update your password",
    origin: "Original password",
    incorrect: "The password is incorrect.",
    passwordInput: "Password",
    secure: "This password is weak",
    prompt: "Password prompt",
    tooltip:
      " The password must be at least 8 digits, including at least\n three types of upper and lower case, letters, and characters.",
    reEnter: "Re-enter password",
    again: "Passwords do not match. Please try again. ",
    signIn: "Save changes and Sign-In",
    samePwd:
      "New password matches the old one. Please choose a different password. ",
  },
};
