<template>
  <div class="container" style="padding: 52px 0">
    <b-row>
      <b-col xl="3" lg="12">
        <logo-text />
      </b-col>
      <b-col xl="3" md="4">
        <span>{{ $t("common.learnMore") }}</span>
        <a href="javascript:void(0);" @click="toPath('/')">{{
          $t("common.aboutUs")
        }}</a>
        <a
          v-if="showDomestic"
          href="javascript:void(0);"
          @click="toPath('/geo-gpt')"
          >{{ $t("common.geoGPT") }}</a
        >
        <a
          v-if="showDom"
          href="javascript:void(0);"
          @click="toPath('/astro-one')"
          >{{ $t("common.astroOne") }}</a
        >
        <!-- <a href="javascript:void(0);" @click="toPath('/projects-programs')">
          {{ $t('common.projectsPrograms') }}
        </a> -->
        <!-- <a href="javascript:void(0);">Resource Hub</a> -->
        <a href="javascript:void(0);" @click="toPath('/news-events')">
          {{ $t("common.newsEvents") }}
        </a>
      </b-col>
      <b-col xl="3" md="4">
        <span>{{ $t("common.policyGuidelines") }}</span>
        <a href="javascript:void(0);" @click="toPath('/privacy-policy')">
          {{ $t("common.privacyPolicy") }}
        </a>
        <a href="javascript:void(0);" @click="toPath('/terms-conditions')">
          {{ $t("common.termsConditions") }}
        </a>
        <!-- <a href="javascript:void(0);">{{ $t("common.support") }}</a> -->
      </b-col>
      <b-col xl="3" md="4">
        <span>{{ $t("common.links") }}</span>
        <a href="https://en.zhejianglab.org/" target="_blank">{{
          $t("common.zhejiangLab")
        }}</a>
        <a href="http://zjdf.zhejianglab.org/home/#/home" target="_blank">
          {{ $t("common.zhijiangDevelopmentFoundation") }}
        </a>
        <a href="https://spj.science.org/journal/icomputing" target="_blank">
          {{ $t("common.intelligentComputing") }}
        </a>
      </b-col>
    </b-row>

    <div class="copyright">
      {{ $t("common.copyright") }}
    </div>
  </div>
</template>

<script>
import LogoText from "@/views/common/LogoText.vue";

export default {
  name: "CommonFooter",
  components: { LogoText },
  data() {
    return {
      showDomestic: false,
      showDom: false,
    };
  },
  created() {
    if (process.env.VUE_APP_DEFAULT_VERSION == "international") {
      this.showDom = false;
      this.showDomestic = true;
    } else if (process.env.VUE_APP_DEFAULT_VERSION == "domestic") {
      this.showDomestic = false;
      this.showDom = true;
    } else {
      this.showDomestic = true;
      this.showDom = true;
    }
  },
  methods: {
    toPath(path) {
      if (path === this.$route.path) {
        return;
      }
      this.$router.push({ path });
      document.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 0;
  text-align: left;

  > div {
    span {
      color: #18181b;
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
    }

    a {
      color: #52525a;
      font-size: 14px;
      text-decoration: none;
    }

    > * {
      display: block;
      margin: 25px 0;
    }
  }
}

.copyright {
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  color: #52525a;
}
</style>
