<template>
  <div class="dropdown">
    <div
      class="select-item"
      :class="{ active: selectValue === 'model' }"
      @click="handleChange('model', '/model-library')"
    >
      <span>{{ $t("common.modelLibrary") }}</span>
    </div>
    <div
      class="select-item"
      :class="{ active: selectValue === 'data' }"
      @click="handleChange('data', '/data-resources')"
    >
      <span>{{ $t("common.dataResources") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectValue: "model",
    };
  },
  mounted() {
    if (localStorage.getItem("resourceType")) {
      this.selectValue = localStorage.getItem("resourceType");
    } else {
      this.selectValue = "model";
    }
  },
  methods: {
    handleChange(val, path) {
      if (path === this.$route.path) {
        return;
      }
      this.selectValue = val;
      localStorage.setItem("resourceType", val);
      this.$emit("option-changed", path);
    },
    handleMouseEnter(item) {
      this.iconStatus = item.iconType;
      this.selectDropDown = "";
    },
    handleMouseLeave() {
      this.iconStatus = this.selectIcon;

      this.selectDropDown = this.selectIcon;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  position: absolute;
  width: 150px;
  height: 102px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(37, 40, 43, 0.12);
  left: 50%;
  .select-item {
    padding: 11px 0;
    display: flex;
    justify-content: center;
    color: #52575c;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1px;
    &:nth-child(1) {
      margin-top: 12px;
    }
  }
  .active {
    background: rgba(0, 126, 255, 0.04);
    span {
      opacity: 1;
      color: #007eff;
      font-family: "PingFang TC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.1px;
    }
  }
}
</style>
