export default {
  common: {
    signIn: "登录",
    home: "首页",
    aboutUs: "关于我们",
    gpt: "GeoGPT",
    projectsPrograms: "项目案例",
    newsEvents: "新闻活动",
    workbench: "工作台",
    geoGPT: "GeoGPT",
    astroOne: "AstroOne",
    resource: "资源",
    modelLibrary: "模型社区",
    dataResources: "数据网络",
    learnMore: "查看更多",
    policyGuidelines: "政策指南",
    privacyPolicy: "个人信息保护政策",
    termsConditions: "平台服务条款",
    support: "用户支持",
    links: "友情链接",
    zhejiangLab: "之江实验室",
    zhijiangDevelopmentFoundation: "之江发展基金会",
    intelligentComputing: "智能计算",
    copyright:
      "浙ICP备18016057号-15 Copyright © 2025, 之江实验室. All Rights Reserved",
    loginFail: "登录失效",
    msg: "提示",
  },
  aboutUs: {
    title: "关于我们",
    openSource: "开源创新",
    openScience1: "开放科学研究过程",
    openScience2: "开放科学数据",
    openScience3: "开放科学基础设施",
    openScience4: "全面的开源许可",
    theFuture: "加速科研的未来科研范式！",
    data: "数据",
    model: "模型",
    computing: "计算",
    scale: "规模 ³",
    currently: `当前，科学发现已进入AI for Science（AI4S）的新时代。数据、模型和计算的规模化发展催生了一系列科技创新。然而，要进一步扩大规模，突破量变到质变的临界状态，亟需全球资源的聚集。在这样的背景下，zero2x应运而生。zero2x的愿景是探索开源创新的合作机制，促使不同主体共同合作，推动开放科学基础设施的建设，从而加速科学变革与科技创新的进程。`,
    projects: "项目案例",
    resources: "开放资源",
    researchers: "参与科研人员",
    overview: "简介",
    zero2x: `zero2x为算力、数据、模型、工具等各类资源提供一站式入口，助力各类人员高效便捷地触达“AI for Science”的各类资源和服务，促进AI时代研究效能、协作水平和科研成果质量的提升，支撑打造开放科学最佳实践。`,
    milestone: "开放科学里程碑",
    milestone2024o1: `FAST“浪人”在球状星团 M15 中发现两颗慢脉冲星`,
    milestone2024o2: "之江实验室上演AI“追星记”",
    milestone2024o3: "GeoGPT：面向地球科学家的大型语言模型系统",
    milestone2024o4: "第四届智能计算创新论坛",
    discoverMore: "了解更多",
  },
  geoGpt: {
    title: "面向地球科学家的开放式大型语言模型系统",
    desc: "GeoGPT是一个开源、非营利性的全球地球科学研究项目，旨在促进协作、共享\n和共建的开放科学原则。",
    product: "体验产品",
    website: "GeoGPT官网",
    check: "查看 GeoGPT 中的新功能！",
    mode: "能力",
    capacity:
      "GeoGPT是一种专门的大型语言模型（LLM），用于支持地球科学研究和应用",
    scientist: "案例",
    idea: "愿景",
    language: "地学领域大语言模型",
    languageDesc:
      "GeoGPT能辅助提出研究创意、文献搜索、阅读文献、提\n取数据、构建知识图谱，并且可用于地质图的理解和制\n作。",
    geologic: "GeoGPT-地质图多模态模型",
    geologicDesc: "地质图多模态模型，能够识别地质图并进行专业问答",
    scientistDesc:
      "通过与领域科学家深度协作，共同构建针对特定领域的科学问题解决方案",
    creature: "古生物分类",
    creatureDesc: "识别和区分孢子类型和特征",
    geologicCreate: "地质图生成",
    geologicCreateDesc: "绘制专业的地质图",
    ideaSummary: "建立地球科学研究的全球生态系统",
    idea1: "开放科学",
    idea1Detail: "合作、共享和共建",
    idea2: "AI伦理",
    idea2Detail: "经过严格的测试和伦理微调",
    idea3: "平等科学",
    idea3Detail: "AI促进科学研究中的平等与公平",
    contact: "联系我们",
  },
  astroOne: {
    title: "天文学家高效可靠的研究助手与合作伙伴",
    product: "体验产品",
    website: "AstroOne官网",
    specClip:
      "SpecClip可以分析不同波长的光谱数据有助于研究人员了解天体的物理和化学特性以及速度细节。该模型在探索宇宙演化、恒星形成和星系动力学方面将至关重要。",
    falco:
      "Falco可以分析目标天体随时间变化的光度，以追踪其变动情况，及时捕捉异常天文事件，并探测新的天体或现象。",
    comming: "尽情期待",
    vl: "VL模型对天文图像进行深入解读，以精确识别关键天体的特征，助力科学家开展跨学科数据分析以及对天文现象的阐释。",
    solar:
      "Solar模型旨在利用长达 12 年的太阳观测数据（700 万张图像和 13 个波段），支持在任意时间跨度内进行特征提取与预测，以实现精确的太阳耀斑预报。",
  },
  home: {
    title1: "Build for Scientific",
    title2: "Data Connection",
    ai: {
      title: "AI驱动，加速科研进程",
      desc: "平台提供的AI工具和算法能够显著加速您的科研进程",
    },
    collaboration: {
      title: "促进跨学科合作",
      desc: "我们平台提供便捷的社区和交流环境，使得来自不同领域的专家、学者和科研人员能够进行深度合作。",
    },
    science: {
      title: "推动开放科学和知识共享",
      desc: "我们平台强调开放科学和资源共享，支持全球科研人员互相交流、分享最新的科研成果与资源。",
    },
    exploreTheZero2x: "探索zero2x",
    exploreImg1: "地球科学",
    exploreImg2: "天文科学",
    availableOpenResources: "可用的开放资源",
    ourPlatform: "我们的平台强调开放科学和资源共享",
    computing: "算力",
    data: "数据",
    models: "模型",
    tools: "工具",
    latestNews: "近期新闻活动",
    by: "来源 ：",
    zhejiangLab: "之江实验室",
    date1: "2024-10-10",
    date2: "2024-06-24",
    date3: "2023-05-29",
    latestNew1: "第四届智能计算创新论坛",
    latestNew2: "孵化洞察：生活中的大语言模型",
    latestNew3: "相聚伦敦|应该人才交流博览会",
    ourPartner: "我们的伙伴",
    ourPartnerDesc: "在开放科学的道路上共进",
  },
  newsEvents: {
    title: "新闻活动",
    viewMore: "查看详情",
    title1: "FAST寻获两颗球状星团长周期脉冲星",
    intro1:
      "球状星团是一种受自引力束缚的高密度恒星集群，其演化年龄一般超过百亿年，是宇宙中最古老的天体系统之一。球状星团中的恒星经过数十亿年的演化，存在着大量脉冲星，通过了解其性质，可获取球状星团的动力学演化过程、星族合成路径等信息。“中国天眼”FAST是世界最大的单口径射电望远镜，其历史性高灵敏度可对球状星团开展最深的射电脉冲星搜寻和观测研究。",
    title2: "科技日报 | 之江实验室上演AI“追星记”",
    intro2:
      "最近一年，之江实验室天文计算研究中心基于FAST的观测数据共发现了31颗脉冲星，其中包括15颗长周期脉冲星。",
    title3: "3个月→2周！“AI+生命科学”，之江实验室为病毒检测按下“加速键”",
    intro3:
      "得益于实验室生命科学计算开放平台最新推出的CRISPR核酸检测设计算法模型，黄柏成及团队成员将试剂盒的研发周期从3个月以上……",
    title4: "成功捕捉喷流周期性进动 M87黑洞最新研究成果登上Nature",
    intro4:
      "9月27日，《自然》（Nature）杂志发布由45个机构组成的国际科研团队的最新研究成果。通过分析2000年至2022年期间的观测数据，发现M87星系中心黑洞喷流呈现周期性摆动，摆动周期约为11年，振幅约为10度。",
    title6:
      "First Revealing of Continuously-active Repeating Fast Radio Burst in Science",
    intro6:
      "On May 12, 2023, a major discovery in the field of fast radio bursts, the Magnetic Field Reversal in the Turbulent Environment Around a Repeating Fast Radio Burst, was published in Science, a top international academic journal",
    title7: "深度挖掘中国天眼观测数据 计算天文最新成果登上Science Bulletin封面",
    intro7:
      "12月26日，之江实验室智能计算平台研究中心研究专家冯毅等科研人员的最新研究成果作为封面文章发表于“中国科技期刊卓越行动计划”综合性领军期刊Science Bulletin上。该成果依托之江实验室联合中科院国家天文台打造的智能计算天文平台，在快速射电暴和脉冲星搜寻探索模型及天文数据处理模型的基础上，利用数据挖掘、机器学习、先进计算等智能计算技术对中国天眼FAST的数据进行了深度挖掘。",
    title8: "Nature Communications报道 器件物理即算法",
    intro8:
      "近来，以大模型为代表的人工智能技术快速发展，带来电力需求激增。据斯坦福人工智能研究所发布的《2023年AI指数报告》，AI大语言模型GPT-3一次训练的耗电量为1287兆瓦时...",
    title9:
      "卫星遥感大数据助推可持续发展 2024联合国大数据黑客松大赛中国赛成功举办",
    intro9:
      "12月12日，由联合国统计司、联合国统计大数据和数据科学全球中心（以下简称全球中心）和之江实验室共同举办的2024联合国大数据黑客松中国赛（以下简称中国赛）决赛暨闭幕式在之江实验室落下帷幕。",
    title10: "天文大语言模型AstroOne正式发布 之江实验室、国家天文台共同出品",
    intro10:
      "10月30日，之江实验室联合中国科学院国家天文台共同打造的天文大语言模型AstroOne在中国天文学会2024年学术年会上首次亮相，吸引了天文学界的广泛关注。",
  },
  workbench: {
    projectName: "这里有您相关的事宜",
    welcome: "欢迎回来",
    desc: "这里有您相关的事宜",
    task: "我的项目",
    help: "帮助文档",
    add: "New Project",
    taskName: "任务名称",
    taskType: "任务类型",
    domainDiscipline: "领域学科",
    status: "状态",
    startTime: "开始时间",
    detail: "详情",
    edit: "编辑",
    delete: "删除",
    invitation: "接受项目邀请",
    welcomeProject: "欢迎参与标注系统项目",
    project: "项目名称",
    Initiator: "创建人",
    accept: "加入",
    reject: "拒绝",
    dueTo: "截至日期",
    accepted: "项目待接受",
    workbench: "工作台",
    editProject: "项目编辑",
    editInfo: "项目编辑信息",
    projectId: "项目编号",
    projectItem: "项目名称",
    projectImg: "项目图片",
    projectDes: "项目介绍",
    scientificFied: "学科领域",
    dueDate: "截止日期",
    projectMembers: "项目成员",
    email: "邮箱地址",
    username: "用户名",
    joinStatus: "状态",
    operation: "操作",
    isAccepted: "已接受",
    isRejected: "已拒绝",
    pending: "未执行",
    sendInvitaion: "发送邀请",
    inviteMember: "邀请人员",
    addByEnter: "完成输入后，点击“enter”键添加人员",
    send: "发送邀请链接",
    confirmEdit: "保存项目修改",
    maxiMum: "项目名称长度不能超过500个字符。",
    duplicateMsg: "有重复邮箱或人员已存在",
    invalidEmail: "请输入正确的邮箱格式",
    noMembers: "尚未添加项目成员",
    noProject: "暂无项目",
    emailName: "邮件",
    inviteFail: "邀请失败",
  },
  privacyPolicy: {
    title: "个人信息保护政策",
    date: "最近更新：2025年1月10日",
  },
  projectsPrograms: {
    title: "项目案例",
    subhead1: "一个开源的、非营利性的地球科学探索研究项目",
    intro1:
      "GeoGPT 是由之江实验室开发的一个开源、非营利的全球地球科学研究探索性项目。它提倡合作、共享和共建的开放科学理念。通过汇集地球科学家、人工智能专家和更广泛研究社区的力量，GeoGPT 致力于开辟新的探索途径，加速突破性发现。",
    subhead2: "以先进的人工智能能力为天文学和天体物理研究赋能",
    intro2:
      "AstroOne 是一个开源的非营利性天文学和天体物理学研究探索性研究项目，为天文学家提供新颖的 LLM 增强功能和工具。来自国家天文台、中国科学院和浙江实验室的数百名人工智能和天文学专家参与了 AstroOne 原型的开发。",
    viewMore: "查看更多",
  },
  termsConditions: {
    title: "平台服务条款",
  },
  personal: {
    setting: "设置",
    update: "更新和管理您的帐户",
    profile: "个人资料",
    edit: "编辑您的个人资料",
    name: "姓名",
    rules: "仅支持英文字母、数字和空格，不超过24个字符数",
    email: "邮箱地址",
    save: "保存修改",
    account: "账户设置",
    change: "修改您的账户密码",
    password: "修改密码",
    logout: "退出zero2x系统",
    exit: "退出",
    updatePassword: "更新您的密码",
    origin: "原密码",
    incorrect: "密码不正确",
    passwordInput: "新密码",
    secure: "此密码的安全级别较低 ",
    prompt: "密码提示",
    tooltip:
      " 密码必须至少为 8 位字符，且至少包括大写字母、小写字母、数字和特殊字符四类中的三种类型。",
    reEnter: "确认新密码",
    again: "两次密码不一致，请重新输入",
    signIn: "保存更改并登录",
    samePwd: "新密码不能与原密码相同",
  },
};
