var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar",style:({
    backgroundColor: _vm.color,
    width: '25px',
    height: '25px',
    lineHeight: '25px',
    fontSize: '10px',
  })},[_vm._v(" "+_vm._s(_vm.name?.slice(0, 2))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }