import { getLanguage } from "@/i18n/index";

const state = {
  device: "desktop",
  lang: getLanguage(),
};

const mutations = {
  SET_LANGUAGE: (state, lang) => {
    state.lang = lang;
    localStorage.setItem("lang", lang);
  },
};

const actions = {
  setLanguage({ commit }, lang) {
    commit("SET_LANGUAGE", lang);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
